import React from "react";

import { Card, Col, OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import { NavLink, useNavigate } from "react-router-dom";
import { Maximize } from "react-feather";


const CagesInTransit = ({ width, name }) => {

  const navigate = useNavigate();

  const getType = (type) => {
    if (type === 'asset') {
      return '/assets/edit'
    } else if (type === 'person') {
      return '/people/edit'
    } else {
      return '/zones/edit'
    }
  }

  const columns = [
    {
      Header: "",
      accessor: "icon",
      type: "icon",
      headerStyle: {
        width: "40px"
      },
      disableSortBy: true
    },
    {
      Header: "Transit",
      accessor: "compoundName",
      disableSortBy: true,
      width: "200px",
      Cell: cell => (
        <NavLink to={{ pathname: getType(cell.row.original.type), search: `?id=${cell.row.original.id}` }}>{`${cell.value} ${cell.row.original.reference ? `(${cell.row.original.reference})` : ""}`}</NavLink>
      )
    },
    {
      Header: "Cages",
      accessor: "contentsCount",
      disableSortBy: true
    },
    {
      Header: "% Total",
      accessor: "contentsPercentage",
      disableSortBy: true,
      className: "w-35",
      Cell: cell => (
        <ProgressBar now={cell.value} label={`${cell.value}%`} />
      )
    }
  ];

  return (
    <React.Fragment>
      <Col key={name} lg={(width / 100 * 12).toFixed(0)} className="mb-4">
        <Card className="flex-fill w-100 h-100" style={{ minHeight: "420px" }}>
          <div className="m-3 d-flex justify-content-between align-items-center">
            <h4 style={{ color: "#E0E0E0" }}>Cages In Transit</h4>
            <div>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip-1">Expand</Tooltip>}
              >
                <Maximize size={18} className="cursor-pointer" onClick={() => navigate(`/cagesInTransit`)} />
              </OverlayTrigger>
            </div>
          </div>
          <CommonTable singlePage showRange={false} secFilterId={"childHashtag"} secId={"cage"}
            filterId={"parentHashtag"} id={"truck"} type="dashboard" apiName={`reports/distribution`} columns={columns} emptyMessage="No cages are at depots sites" />
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default CagesInTransit;
