import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Dropdown, Form, Nav, Tab } from "react-bootstrap";
import Markers from "../../pages/maps/components/Markers";
import { faCalendarCheck, faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TimeLine from "./TimeLine";
import { Link, NavLink } from "react-router-dom";
import { faBinoculars, faBroadcastTower, faLocationArrow, faMap, faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";
import CommonTable from "../tables/CommonTable";
import moment from "moment-timezone";
import { dateFromNow, downloadFile } from "../../utils/staticMethods";
import useAuth from "../../hooks/useAuth";
import TasksTable from "../../pages/task/TasksTable";

const columns = [
  {
    Header: "Name",
    accessor: "childName",
    disableSortBy: true,
    Cell: cell => (
      <div className="d-flex align-items-center"><NavLink to={{
        pathname: `/${cell.row.original.childType === "person" ? "people" : `${cell.row.original.childType}s`}/edit`,
        search: `?id=${cell.row.original.childId}`
      }}>{cell.row.original.childName + `${cell.row.original.childReference ? ` (${cell.row.original.childReference})` : ""}`}</NavLink>
        {cell.row.original.childHashTags && cell.row.original.childHashTags.map(item => <div key={item}
                                                                                                   className="ms-1 badge bg-primary me-1">{item}</div>)}
      </div>
    )
  },
  {
    Header: "Arrived",
    accessor: "start",
    type: "dateFromNow"
  },
  {
    Header: "Time",
    accessor: "end",
    Cell: cell => (
      <span>{moment(cell.row.original?.start).format(("DD/MM/YY HH:mm"))}</span>
    )
  }
];

const OverviewTab = ({ id, trackers, onGetAttendance, apiName, data, tab }) => {

  const [findAddress, setFindAddress] = useState(false);
  const [following, setFollowing] = useState(true);
  const [hideOther, setHideOther] = useState(true);
  const [filterResult, setFilter] = useState("");
  const [count, setCountParent] = useState(0);
  const notify = useContext(NotyfContext);
  const [hide, setHide] = useState(false);
  const [tower, setTower] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    setFilter("")
    setTower(null)
    setFindAddress(false)
    setHide(false)
    if(user) {
      setHideOther(!user.defaultShowOthers)
      setFollowing(user.defaultFollow)
      return
    }
    setHideOther(true)
    setFollowing(true)
  }, [id, user])

  const trackerCommand = (deviceId, commandId) => {
    api.post(`trackers/${deviceId}/sendcommand/${commandId}`)
      .then(res => {
        notify.open({
          type: "success",
          message: "Command Sent"
        });
      });
  };

  const exportFile = () => {
    api.get(`${apiName}/livecontents/${id}/export?filter=${filterResult}`, { responseType: "blob" }).then(res => downloadFile(res));
  };

  const getCellTower = () => {
    if (tower) {
      setTower(null);
    } else {
      let obj = {
        token: "97ad1957b95990",
        radio: "gsm",
        mcc: data?.lastPosition?.mcc,
        mnc: data?.lastPosition?.mnc,
        cells: [{ lac: data?.lastPosition?.lac, cid: data?.lastPosition?.cid }],
        address: 1
      };

      api.post(`https://eu1.unwiredlabs.com/v2/process.php`, JSON.stringify(obj), { headers: { "Content-Type": "application/json" } }).then(r => {
        setTower(r.data);
      }).catch(e => {
        notify.open({
          type: "error",
          message: "No Cell Data"
        });
        setTower(null);
      });
    }
  };

  return (
    <React.Fragment>
      {data.lastPosition && <div>
        <Card.Body className="text-center">
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            {!hide && <>
              {trackers && trackers.length > 0 && trackers[0].commands && trackers[0].commands.length > 0 && user?.role !== "Root" && <Dropdown>
                <Dropdown.Toggle variant="success" size="sm" className="m-1">
                  <FontAwesomeIcon icon={faLocationArrow} size="sm" />
                  Send Command
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {
                    trackers[0].commands.map((command, index) => <Dropdown.Item key={index}
                                                                                onClick={() => trackerCommand(trackers[0].id, command.commandId)}>{command.commandName}</Dropdown.Item>)
                  }
                </Dropdown.Menu>
              </Dropdown>}
              <Button variant={tower ? `success` : "primary"} size="sm" className="m-1"
                      onClick={() => getCellTower()}><FontAwesomeIcon size="sm"
                                                                      icon={faBroadcastTower} /> Cell
                Tower</Button>
              <Button size="sm" onClick={() => setFindAddress(true)} className="ms-1"><FontAwesomeIcon
                icon={faBinoculars} /> Find Address</Button>
            </>}
            <ButtonGroup>
              <Link
                to={{
                  pathname: "/report/positions",
                  search: `?id=${id}&name=${encodeURIComponent(data.compoundName)}&type=${apiName}`
                }}>
                <Button size="sm" className="m-1"><FontAwesomeIcon icon={faMap} size="sm" /> View History</Button>
              </Link>
              <Form.Check
                className="m-1"
                type="switch"
                size="sm"
                checked={following}
                onChange={e => setFollowing(e.target.checked)}
                label="Follow"
              >
              </Form.Check>
              {user?.role !== "Root" && <Form.Check
                className="m-1"
                type="switch"
                size="sm"
                checked={!hideOther}
                onChange={e => setHideOther(!e.target.checked)}
                label="Show Others"
              >
              </Form.Check>}
            </ButtonGroup>
          </div>
        </Card.Body>
        {tab === "overview" &&
        <Markers hideOther={hideOther} tower={tower} hideAction={() => setHide(true)} tab={tab}
                 following={following}
                 onCancelSearch={() => setFindAddress(false)}
                 findAddress={findAddress} id={id} data={data} />}
      </div>}
      <div className="sub-tab mt-4">
        <Tab.Container defaultActiveKey="attendance">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link className="white-text" eventKey="attendance">Attendances</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="white-text" eventKey="contents">Contents</Nav.Link>
            </Nav.Item>
            {user?.showTasks && <Nav.Item>
              <Nav.Link eventKey="tasks">Tasks</Nav.Link>
            </Nav.Item>}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="contents">
              <div className="d-flex justify-content-between align-items-center mb-2 flex-wrap">
                <h4 className="mb-0">
                  <FontAwesomeIcon icon={faCalendarCheck} fixedWidth className="fa-fw" />
                  <span className="ms-1">Current Contents {typeof(count) === 'string' && `(${count})`}</span>
                </h4>
                <div>
                  <Button className="me-1 mb-1 mb-sm-0" disabled={Number(count) <= 0} onClick={() => exportFile()}
                          variant="success">Export</Button>
                  <NavLink to={{
                    pathname: "/report/attendance",
                    search: `?id=${id}&name=${encodeURIComponent(data.compoundName)}&type=${apiName}Past`
                  }}><Button>View History</Button></NavLink>
                </div>
              </div>
              {data.id === id && <CommonTable filterResult={e => setFilter(e)} autoRefresh hideAuto bordered
                                              setCountParent={setCountParent}
                                              apiName={`${apiName}/livecontents/${id}`} columns={columns} />}
            </Tab.Pane>
            <Tab.Pane eventKey="attendance">
              {data && data.lastAttendance && data.lastAttendance.completionStatus === false && <div className='d-flex flex-column justify-content-between mb-2'>
                <h4 className="mb-4">
                  <FontAwesomeIcon icon={faCalendarCheck} fixedWidth className="fa-fw" />
                  <span className={"ms-1"}>Live Attendance</span>
                </h4>
                  <div className="ps-6 pb-5 border-bottom" >
                    <h3 className="mb-1">
                      <NavLink to={{pathname: `/${data.lastAttendance.parentType === 'asset' ? 'assets' : 'zones'}/edit`, search: `?id=${data.lastAttendance.parentId}`}} className="link-primary">{data.lastAttendance.parentName}</NavLink>
                    </h3>
                    {data.lastAttendance.parentHashTags && data.lastAttendance.parentHashTags.map(item1 => <div key={item1} className="ms-1 badge bg-primary me-1">{item1}</div>)}
                    <div className="mt-1">
                      <FontAwesomeIcon icon={faSignInAlt} fixedWidth />
                      <span className="ms-1">Arrived:</span>
                      <span>&nbsp; {moment(data.lastAttendance.start).format("DD/MM/YYYY HH:mm")}</span>
                    </div>
                    <div>
                      <FontAwesomeIcon icon={faClock} fixedWidth />
                      <span>&nbsp; {dateFromNow(data.lastAttendance.start)}</span>
                    </div>
                  </div>
              </div>}
              <div className="d-flex justify-content-between mb-2 align-items-center flex-wrap">
                <h4 className="mb-0">
                  <FontAwesomeIcon icon={faCalendarCheck} fixedWidth className="fa-fw" />
                  <span className={"ms-1"}>Attendance History</span>
                </h4>
                <NavLink to={{
                  pathname: "/report/attendance",
                  search: `?id=${id}&name=${encodeURIComponent(data.compoundName)}&type=${apiName}`
                }}><Button>View All</Button></NavLink>
              </div>
              <TimeLine apiName={apiName} onGetAttendance={onGetAttendance} id={id} name={data.compoundName} />
            </Tab.Pane>
            <Tab.Pane eventKey="tasks">
              {id && <TasksTable type={apiName} id={id} />}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </React.Fragment>
  );
}

export default OverviewTab;