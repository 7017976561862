import React, { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";
import defaultImg from "../../assets/img/icon/Aircraft/Airliner_Black.png";
import GoogleMapReact from "google-map-react";
import { imageSet } from "../../pages/icons/AssetIcon/IconList";
import SearchBox from "../../pages/maps/components/SearchBox";
import api from "../../api";
import GroupSelector from "../../components/groupSelector/GroupSelector";
import { GridAlgorithm, MarkerClusterer } from "@googlemaps/markerclusterer";
import MapDetail from "../detail/MapDetail";
import axios from "axios";
import { globalConfig } from "../../config";
import circle from "../../assets/img/clusterImg/m1.png";
import { getDistance, getUrl, radiusZoomLevel, resArr, updateMarker } from "../../utils/staticMethods";
import MarkerModal from "../mapComponents/MarkerModal";
import NearByAssets from "../mapComponents/NearByAssets";
import useAuth from "../../hooks/useAuth";
import InfoWindow from "../mapComponents/InfoWindow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowMaximize } from "@fortawesome/free-solid-svg-icons";

let timer = null;
let markerArr = [];
let followingTimer = null;
let markerCluster = null;
let intervalNearby = null;
let zoomTimer = null;
let mapType = "roadmap";
let source = null;
const thingsArr = { asset: "assets", person: "people", zone: "zones", assets: 'assets', people: 'people', zones: 'zones' };

// map widget in dashboard, similar logic with MapDashboard.js
const MiniMap = React.memo(({ width, name }) => {
  const [following, setFollowing] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [mapInstance, setInstance] = useState();
  const [mapApi, setApi] = useState();
  const [zoomLevel, setZoomLevel] = useState(18);
  const [overlay, setOverlay] = useState(false);
  const [infoWindow, setInfoWindow] = useState(null);
  const limit = 450;
  const [center, setCenter] = useState(null);
  const [current, setCurrent] = useState(null);
  const [firstLoad, setFirstLoad] = useState(false);
  const [filter, setFilter] = useState("");
  const [infoItem, setInfoItem] = useState([]);
  const infoItemRef = useRef(infoItem);
  const [nearByData, setNearByData] = useState([]);
  const infoRef = useRef(infoWindow);
  const follow = useRef(following);
  const centerRef = useRef(center);
  const currentRef = useRef(current);
  const zoomLevelRef = useRef(zoomLevel);
  const searchBox = useRef();
  const filterRef = useRef(filter);
  const { user } = useAuth()
  const [tableData, setTableData] = useState(null);

  useEffect(() => {
    return () => {
      clearTimeout(timer);
      timer = null;
      markerArr = [];
      markerCluster = null;
      clearInterval(intervalNearby);
      clearInterval(followingTimer);
      intervalNearby = null;
      followingTimer = null;
      clearTimeout(zoomTimer);
      zoomTimer = null;
    };
  }, []);

  useEffect(() => {
    if(user && mapApi && mapInstance && current && user.defaultFollow) {
      setFollowing(true)
      follow.current = true
    }
  }, [user, mapApi, mapInstance, current])

  useEffect(() => {
    if (following && current) {
      followingTimer = setInterval(() => {
        getDetail();
      }, 5000);
    } else if (!following) {
      followingTimer && clearInterval(followingTimer);
    }
  }, [following]);

  const getDetail = () => {
    api.get(`${currentRef.current.type}/${currentRef.current.id}`).then(res => {
      let c = res.data;
      c.type = currentRef.current.type;
      if ((c.type === "assets" || c.type === "people") && c.lastPosition && c.lastPosition.latitude && c.lastPosition.longitude && mapApi && mapInstance) {
        if (follow.current && (Number(c.lastPosition.latitude) !== Number(currentRef.current.lastPosition.latitude) || Number(c.lastPosition.longitude) !== Number(currentRef.current.lastPosition.longitude))) {
          mapInstance.panTo({ lat: Number(c.lastPosition.latitude), lng: Number(c.lastPosition.longitude) });
          setCenter({ lat: Number(c.lastPosition.latitude), lng: Number(c.lastPosition.longitude) });
          centerRef.current = { lat: Number(c.lastPosition.latitude), lng: Number(c.lastPosition.longitude) };
        }
      }
      setCurrent(c);
      currentRef.current = c;
    });
  };

  useEffect(() => {
    if (mapApi && mapInstance) {
      window.map = mapInstance;
      setInfoWindow(new mapApi.InfoWindow({
        content: ""
      }));
      infoRef.current = new mapApi.InfoWindow({
        content: ""
      });
      if (firstLoad && current && center) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          zoomLevel !== mapInstance.getZoom() && mapInstance.setZoom(zoomLevelRef.current);
          getNearBy();
          if (intervalNearby) {
            clearInterval(intervalNearby);
            intervalNearby = null;
          }
          intervalNearby = setInterval(() => {
            getNearBy();
          }, 5000);
        }, 700);
      }
    }
  }, [zoomLevel, center, mapApi, mapInstance, firstLoad]);

  const getNearBy = async () => {
    if (!mapInstance.getCenter()) return;
    let count = [0, 0, 0];
    if(source){
      source.cancel('request canceled');
    }
    source = axios.CancelToken.source();
    const res = await api.get(`maps/nearby?radius=${radiusZoomLevel[Math.round(zoomLevelRef.current)]}&latitude=${ mapInstance.getCenter().lat()}&longitude=${mapInstance.getCenter().lng()}&limit=${limit}`, {
      cancelToken: source.token
    });

    const totalHeader = res.headers["x-total-count"];
    if (Number(totalHeader) >= limit) {
      setNearByData([])
      count[0] = Number(totalHeader);
      setOverlay(true);
      markerArr && markerArr.forEach(item => {
        item.setMap(null);
        item = null;
      });
      markerArr = [];
      if (markerCluster) {
        markerCluster.clearMarkers();
        markerCluster.setMap(null);
        markerCluster = null;
      }
    } else {
      setNearByData(res.data);
      setOverlay(false);
      let arr = res.data;
      if (arr.length > 0) {
        let infoArr = infoItemRef.current.concat([])
        updateMarker(arr, markerArr, infoArr, mapInstance, mapApi);
        let removeArr = resArr(markerArr, arr);
        removeMaker(removeArr, infoArr)
        let newArr = resArr(arr, markerArr);
        showMarker(newArr, infoArr);
        setInfoItem(infoArr);
      }
    }
    timer = null;
  };

  useEffect(() => {
    infoItemRef.current = infoItem
  }, [infoItem])

  const removeMaker = (removeArr, infoArr) => {
    removeArr.forEach(item => {
      let index = infoArr.findIndex(f => f.lastPosition.id === item.id)
      if(index > -1) {
        infoArr[index].hide = true
      }
      for (let i = 0; i < markerArr.length; i++) {
        if (markerArr[i].idsArr.includes(item.id)) {
          if(markerArr[i].idsArr.length === 1) {
            markerArr[i].setMap && markerArr[i].setMap(null);
            markerCluster && markerCluster.removeMarker(markerArr[i]);
            markerArr.splice(i, 1)
            break
          } else if(markerArr[i].idsArr.length > 1) {
            markerArr[i].idsArr = markerArr[i].idsArr.filter(arrItem => arrItem !== item.id)
            markerArr[i].ids = markerArr[i].ids.filter(arrItem => arrItem.id !== item.id)
            markerArr[i].id = markerArr[i].idsArr[0]
            markerArr[i].setMap && markerArr[i].setMap(mapInstance);
            if(markerArr[i].idsArr.length === 1) {
              markerArr[i].setIcon({...markerArr[i].icon , scaledSize: new mapApi.Size(24, 24), url: imageSet[getUrl(markerArr[i].ids[0].icon)] ? require("../../assets/img/icon/" + imageSet[getUrl(markerArr[i].ids[0].icon)]).default : defaultImg});
              markerArr[i].setLabel({className: "marker-position-bottom", color: "#000000", fontWeight: "bold", text: markerArr[i].ids[0].compoundName})
              mapApi.event.clearListeners(markerArr[i], "click");
              // eslint-disable-next-line no-loop-func
              markerArr[i].addListener("click", function(e) {
                e.domEvent.stopImmediatePropagation();
                setCenter(markerArr[i].position);
                centerRef.current = markerArr[i].position;
                mapInstance.panTo(markerArr[i].position);
                if(mapInstance.getZoom() < 18) {
                  mapInstance.setZoom(18)
                }
                let cur = this.ids[0];
                cur.lastPosition = this.ids[0];
                cur.type = this.type;
                setCurrent(cur);
                currentRef.current = cur;
                setInfoItem(pre => {
                  let obj = { lastPosition: this.ids[0] };
                  if (pre.length === 0) {
                    return [obj];
                  }
                  let arr = pre.concat([])
                  let filter = pre.filter(item => item?.lastPosition?.id !== obj.lastPosition.id);
                  if (filter.length === pre.length) {
                    arr.push(obj);
                    return arr;
                  } else {
                    return filter;
                  }
                });
              });
            }
            break
          }
        }
      }
    });
  }

  const showMarker = (newArr, infoArr) => {
    newArr.forEach(item => {
      let filter = markerArr.filter(markerMe => (markerMe.type !== "zone" && markerMe.type !== "zones" && markerMe.position.lat().toFixed(5) === Number(item.latitude).toFixed(5) && markerMe.position.lng().toFixed(5) === Number(item.longitude).toFixed(5)));
      if (filter.length > 0 && item.type !== "zone" && item.type !== "zones") {
        if (item.type === "asset") filter[0].asset++;
        if (item.type === "person") filter[0].person++;
        filter[0].ids.push(item);
        filter[0].idsArr.push(item.id);
        filter[0].label.text = ' ';
        filter[0].label.className = "font-bold";
        filter[0].icon.url = circle;
        filter[0].icon.scaledSize = new mapApi.Size(35, 35);
        if(filter[0].idsArr.includes(currentRef.current.id) && markerCluster) {
          markerCluster.removeMarker(filter[0]);
        }
        mapApi.event.clearListeners(filter[0], "click");
        filter[0].addListener("click", (e) => {
          e.domEvent.stopImmediatePropagation();
          setTableData(filter[0].ids);
        });
        filter[0].setMap(mapInstance)
      } else if ((item.type === "zone" || item.type === "zones") && item.latitude && item.longitude) {
        let m = new mapApi.Marker({
          id: item.id,
          type: "zones",
          asset: item.type === 'asset' ? 1 : 0,
          person: item.type === 'person' ? 1 : 0,
          zone: item.type === 'zone' ? 1 : 0,
          ids: [item],
          idsArr: [item.id],
          position: { lat: Number(item.latitude), lng: Number(Number(item.longitude)) },
          label: {
            text: item.compoundName,
            color: "#000000",
            className: "marker-position-bottom",
            fontWeight: 'bold'
          },
          icon: {
            url: imageSet[getUrl(item.icon)] ? require("../../assets/img/icon/" + imageSet[getUrl(item.icon)]).default : defaultImg,
            scaledSize: new mapApi.Size(24, 24),
            anchor: new mapApi.Point(12, 12),
          },
          anchor: new mapApi.Point(14, 43),
          map: mapInstance
        });
        m.addListener("click", function(e) {
          e.domEvent.stopImmediatePropagation();
          let cur = JSON.parse(JSON.stringify(m.ids[0]));
          cur.type = m.type;
          setCurrent(cur);
          currentRef.current = cur;
          setCenter(m.position);
          centerRef.current = m.position;
          mapInstance.panTo(m.position);
          let keys = Object.keys(radiusZoomLevel).reverse();
          let value = 1000000;
          let index = 0
          for (let i = 0; i < keys.length; i++) {
            let v = Math.abs(radiusZoomLevel[keys[i]] - m.diam);
            if (v < value) {
              value = v;
              index = keys[i]
            }
          }
          mapInstance.setZoom(Number(index));
        });
        markerArr.push(m);
        if (item.radius) {
          let c = new mapApi.Circle({
            id: item.id,
            idsArr: [item.id],
            ids: [item],
            strokeColor: "#090B29",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#090B29",
            fillOpacity: 0.4,
            shape: "circle",
            type: "zone",
            center: { lat: Number(item.latitude), lng: Number(Number(item.longitude)) },
            map: mapInstance,
            radius: Number(item.radius)
          });
          m.diam = Number(item.radius) * 2
          markerArr.push(c);
        } else if (item.polygon) {
          let firstItem = item.polygon[0].split(',')
          let minLat = {lat: Number(firstItem[1]), lng: Number(firstItem[0])}, maxLat = {lat: Number(firstItem[1]), lng: Number(firstItem[0])}, minLng = {lat: Number(firstItem[1]), lng: Number(firstItem[0])}, maxLng = {lat: Number(firstItem[1]), lng: Number(firstItem[0])};
          let arr = item.polygon.map(item => {
            let me = item.split(",");
            let lng = Number(me[0]);
            let lat = Number(me[1]);
            if (lat > maxLat.lat) maxLat = {lat, lng};
            if (lat < minLat.lat) minLat = {lat, lng};
            if (lng > maxLng.lng) maxLng = {lat, lng};
            if (lng < minLng.lng) minLng = {lat, lng};
            return { lng, lat };
          });
          let point1 = new mapApi.LatLng(minLat);
          let point2 = new mapApi.LatLng(maxLat);
          let point3 = new mapApi.LatLng(minLng);
          let point4 = new mapApi.LatLng(maxLng);
          let d1 = getDistance(point1, point2);
          let d2 = getDistance(point3, point4);
          let d = d1 > d2 ? d1 : d2;
          m.diam = Number(d)
          let polygon = new mapApi.Polygon({
            paths: arr,
            idsArr: [item.id],
            id: item.id,
            ids: [item],
            type: "zone",
            shape: "polygon",
            strokeColor: "#000000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#242424",
            fillOpacity: 0.4,
            editable: false,
            map: mapInstance
          });
          markerArr.push(polygon);
        }
      } else if(item.latitude && item.longitude) {
        let markerFilter = markerArr && markerArr.filter(markerMe => markerMe?.type === "zones" && markerMe?.position.lat().toFixed(5) === Number(item?.latitude).toFixed(5) && markerMe?.position.lng().toFixed(5) === Number(item?.longitude).toFixed(5));
        let m = new mapApi.Marker({
          id: item.id,
          asset: item.type === 'asset' ? 1 : 0,
          person: item.type === 'person' ? 1 : 0,
          zone: item.type === 'zone' ? 1 : 0,
          ids: [item],
          idsArr: [item.id],
          position: { lat: Number(item.latitude || item.lastPosition?.latitude), lng: Number(item.longitude || item.lastPosition?.longitude) },
          label: {
            text: item.compoundName,
            color: "#000000",
            className: "marker-position-bottom",
            fontWeight: 'bold'
          },
          type: thingsArr[item.type],
          icon: {
            url: imageSet[getUrl(item.icon)] ? require("../../assets/img/icon/" + imageSet[getUrl(item.icon)]).default : defaultImg,
            scaledSize: new mapApi.Size(24, 24),
            anchor: new mapApi.Point(12, 12),
            labelOrigin: new mapApi.Point(12, markerFilter && markerFilter.length > 0 ? -48 : 12),
          },
          anchor: new mapApi.Point(14, 43),
          map: mapInstance
        });
        let filter = infoArr.filter(f => f.lastPosition.id === item.id)
        if(filter.length > 0) {
          filter[0].lastPosition.latitude = item.latitude
          filter[0].lastPosition.longitude = item.longitude
          filter[0].hide = false
        }
        m.addListener("click", function(e) {
          e.domEvent.stopImmediatePropagation();
          setCenter(m.position);
          centerRef.current = m.position;
          mapInstance.panTo(m.position);
          if(mapInstance.getZoom() < 18) {
            mapInstance.setZoom(18)
          }
          let cur = this.ids[0];
          cur.lastPosition = this.ids[0];
          cur.type = m.type;
          setCurrent(cur);
          currentRef.current = cur;
          setInfoItem(pre => {
            let obj = { lastPosition: this.ids[0] };
            if (pre.length === 0) {
              return [obj];
            }
            let arr = pre.concat([])
            let filter = pre.filter(item => item?.lastPosition?.id !== obj.lastPosition.id);
            if (filter.length === pre.length) {
              arr.push(obj);
              return arr;
            } else {
              return filter;
            }
          });
        });
        markerArr.push(m);
        if(m.id !== currentRef.current.id && markerCluster) {
          markerCluster.addMarker(m);
        }
      }
    });

    if (!markerCluster && markerArr.filter(item => (item.type !== "zone" && item.type !== "zones")).length > 1) {
      markerCluster = new MarkerClusterer({map: mapInstance, markers:markerArr.filter(item => (item.type !== "zone" && item.type !== "zones")), renderer: {
          render({position}) {
            return new mapApi.Marker({
              label: "",
              position,
              icon: {
                url: circle,
                scaledSize: new mapApi.Size(36, 36),
                anchor: new mapApi.Point(18, 18),
              },
            });
          }
        }, algorithm: new GridAlgorithm({
          gridSize: 7,
          maxZoom: 18,
        }), onClusterClick(e, cluster){
          mapInstance.setZoom(18)
          mapInstance.setCenter(cluster.marker.position)
          centerRef.current = cluster.marker.position
        }});
    }
  }

  const onSelect = (e) => {
    setCurrent(e);
    currentRef.current = e;
    if ((e.type === "assets" || e.type === "people") && e.lastPosition && e.lastPosition.latitude && e.lastPosition.longitude && mapApi && mapInstance) {
      mapInstance.panTo({ lat: Number(e.lastPosition.latitude), lng: Number(e.lastPosition.longitude) });
      setCenter({ lat: Number(e.lastPosition.latitude), lng: Number(e.lastPosition.longitude) });
      centerRef.current = { lat: Number(e.lastPosition.latitude), lng: Number(e.lastPosition.longitude) };
      mapInstance.setZoom(21);
    } else if (e.type === "zones" && e.longitude && e.latitude) {
      mapInstance.panTo({ lat: Number(e.latitude), lng: Number(e.longitude) });
      setCenter({ lat: Number(e.latitude), lng: Number(e.longitude) });
      centerRef.current = { lat: Number(e.latitude), lng: Number(e.longitude) };
      mapInstance.setZoom(21);
    }
  };

  const apiHasLoaded = (map, maps) => {
    if(!map || !maps) return
    setInstance(map);
    setApi(maps);
    map.setOptions({
      fullscreenControl: true,
      mapTypeControl: true,
      mapTypeId: mapType,
      scaleControl: true,
      gestureHandling: "cooperative",
      streetViewControl: true,
      mapTypeControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM
      }
    });
    maps.event.addListener(map, "zoom_changed", function() {
      if (!filterRef.current) {
        setZoomLevel(map.getZoom());
        zoomLevelRef.current = map.getZoom();
        // setTimeout(() => {
        //   setCenter(map.getCenter());
        // }, 200);
      } else {
        setTimeout(() => {
          setCenter(map.getCenter());
          setZoomLevel(map.getZoom());
          zoomLevelRef.current = map.getZoom();
          setFilter('');
          filterRef.current = ''
        }, 200);
      }
    });
    map.addListener("dragend", () => {
      setCenter(map.getCenter());
      centerRef.current = map.getCenter();
      setZoomLevel(map.getZoom());
      zoomLevelRef.current = map.getZoom();
    });
    map.addListener("dragstart", () => {
      if (intervalNearby) {
        clearInterval(intervalNearby);
        intervalNearby = null;
      }
    });
    let thePanorama = map.getStreetView();
    maps.event.addListener(thePanorama, "visible_changed", function() {
      if (thePanorama.getVisible()) {
        setShowSearch(false);
      } else {
        setShowSearch(true);
      }
    });
    api.get(`maps/defaultitem`).then(res1 => {
      let c = res1.data;
      if(!c) {
        setFirstLoad(true);
        return
      }
      c.type = thingsArr[c.type];
      api.get(`${c.type}/${c.id}`).then(res => {
        let cur = res.data;
        cur.type = c.type;
        setCurrent(cur);
        currentRef.current = cur;
        setCenter({ lat: Number(c.latitude), lng: Number(c.longitude) });
        centerRef.current = { lat: Number(c.latitude), lng: Number(c.longitude) };
        setZoomLevel(18);
        zoomLevelRef.current = 18;
        map.panTo(centerRef.current)
        setFirstLoad(true);
      });
    });
  };

  const addPlace = (s) => {
    setFilter(s);
    filterRef.current = s;
  };

  const clickTarget = (item) => {
    let arr = infoItem.concat([]);
    arr.forEach(i => {
      if (i.lastPosition.id === item.lastPosition.id) {
        i.active = true;
      } else {
        i.active = false;
      }
    });
    setInfoItem(arr);
  };

  const onClose = (e, item) => {
    e.stopPropagation();
    setInfoItem(pre => {
      let filter = pre.filter(i => i.lastPosition.id !== item.lastPosition.id);
      return filter;
    });
  };

  const handleAll = () => {
    if (infoItemRef.current.length === 0) {
      let arr = markerArr.filter(item => (item.ids.length === 1 && item.type !== "zones" && item.type !== "zone"));
      let newArr = arr.map(item => {
        let obj = { lastPosition: item.ids[0] };
        return obj;
      });
      setInfoItem(newArr);
    } else {
      setInfoItem([]);
    }
  };

  return (
    <React.Fragment>
      <Col key={name} lg={(width / 100 * 12).toFixed(0)} className="position-relative mb-4">
        {overlay &&
        <div className="p-3 ps-2 bg-light text-warning">Too many items to show. Please zoom or search to narrow
          results</div>}
        {current && !overlay ? (<MapDetail profile={current} following={following} onChangeToggle={(e) => {
          follow.current = e.target.checked;
          setFollowing(e.target.checked);
        }} />) : null}
        {showSearch && mapApi && <div className="position-absolute mobile-style z-50">
          <GroupSelector onSelect={onSelect} />
        </div>}
        {user?.role !== "Root" && mapApi && <div className="position-absolute mt-5 z-50 d-flex">
          <NearByAssets result={nearByData}  />
          <div onClick={() => handleAll()} style={{ left: "30px" }} className="cursor-pointer ms-1">
            <FontAwesomeIcon title={"All labels"} className="bg-black"
                             icon={faWindowMaximize} size={"lg"} color={"#293042"} fixedWidth />
          </div>
        </div>}
        <GoogleMapReact
          style={{ height: "60vh", position: "relative" }}
          bootstrapURLKeys={{
            key: globalConfig.googleMapKey,
            libraries: ["places", "geometry", "drawing", "visualization"]
          }}
          onClick={(ev) => {
            infoRef.current && infoRef.current.close();
          }}
          defaultZoom={0}
          defaultCenter={[
            51.5288684709715,
            -0.10159865243033028
          ]}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
        >
          {mapInstance && mapApi &&
          <SearchBox ref={searchBox} map={mapInstance} mapApi={mapApi} addplace={(s) => addPlace(s)} />}
          {infoItem && infoItem.map(item => <InfoWindow key={item.lastPosition.id} clickTarget={clickTarget}
                                                        lat={item?.lastPosition.latitude}
                                                        lng={item?.lastPosition.longitude} show={item}
                                                        onClose={(e) => onClose(e, item)} />)}
        </GoogleMapReact>
      </Col>
      <MarkerModal tableData={tableData} setTableData={setTableData} />
    </React.Fragment>
  );
});

export default MiniMap;
